.competences, .hooks {
    background: $color-1;
    color: white;

    .competences-container, .hooks-container {
        // max-width: 1450px;
        margin: auto;
        padding: 20px 40px;
        text-align: center;

        @media screen and (max-width: 900px) {
            padding: 20px 20px;
        }

        h3 {
            text-transform: uppercase;
            padding-bottom: 25px;
        }

        .slick-slider {

            i {
                font-size: 6rem;

                @media screen and (max-width: 900px) {
                    font-size: 4rem;
                }
            }

            img {
                margin: 0 auto;
                width: 96px;

                @media screen and (max-width: 900px) {
                    width: 64px;
                }
            }

            .slick-dots {
                position: initial;

                li button {

                    &:before {
                        color: white;
                    }
                }
            }    
        }
    }  
}
