#contact {

    .contact-container {
        // max-width: 1450px;
        margin: auto;
        padding: 0 40px;

        @media screen and (max-width: 900px) {
            padding: 0 20px;
        }

        h3 {
            color: $color-1;
        }

        h2 {
            padding: 10px 0;
            color: $black-title;
        }

        .contact-boxes {
            margin: 40px 0;
            display: flex;
            gap: 100px;
            justify-content: space-between;

            @media screen and (max-width: 1300px) {
                gap: 30px;
            }

            @media screen and (max-width: 1100px) {
                flex-direction: column;
                text-align: center;
            }

            .form-contact {
                width: 100%;
                transform: translateX(-80px);
                opacity: 0;

                &.visible {
                    transform: translateX(0);
                    opacity: 1;
                    transition: all 1s ease-in-out;
                }

                .details-contact {
                    margin-top: 15px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    justify-content: space-between;
                    gap: 25px;
                    width: 100%;

                    @media screen and (max-width: 600px) {
                        grid-template-columns: 1fr;
                    }
                }

                input, textarea {
                    border: 1px solid #CED4DA;
                    border-radius: 5px;
                    padding: 15px;
                    font-family: "Poppins", "Segoe UI", "Helvetica Neue", Arial, sans-serif;
                    font-size: 1rem;
                    outline: none;
                    transition: 0.3s;

                    &:focus {
                        border: 1px solid $color-1;
                    } 

                    &::placeholder {
                        color: rgb(116,125,132);
                        font-size: 1rem;
                    }
                }

                textarea {
                    margin-top: 25px;
                    width: 100%;
                    height: 150px;
                    resize: none;
                }

                input[type="submit"] {
                    background: $color-1;
                    color: white;
                    font-weight: 700;
                    border-radius: 10px;
                    margin-top: 15px;
                    cursor: pointer;
                    transition: 0.3s;

                    &:hover {
                        transform: translateX(8px);
                        background: rgb(10, 127, 131);
                    }
                }
            }

            .map-contact {
                width: 100%;
                transform: translateX(-120px);
                opacity: 0;

                &.visible {
                    transform: translateX(0);
                    opacity: 1;
                    transition: all 1.2s ease-in-out 0.5s;
                }
            }
        }
    }
}