header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    padding: 0 25px 0 80px;
    height: 10vh;
    color: white; 
    
    @media screen and (max-width: 900px) {
        position: relative;
        background: $color-1;
    }

    @media screen and (max-width: 1400px) {
        width: 90%;
    }

    @media screen and (max-width: 900px) {
        width: 100%;
        padding: 0 20px;
    }
    
    &.active {
        position: sticky;
        top: -10vh;
        z-index: 900;
        width: 100%;
        box-shadow: 0 0 10px rgba(0, 0, 0, .09);  
        color: $black-title;
        padding: 0 80px; 
        transition: 0.4s;
        
        @media screen and (max-width: 900px) {
            padding: 0 20px;
        }
        
        &.scroll-head {
            background: white; 
            top: 0;
        }

        i {
            color: $black-title;         
        }

        ul {
            color: $black-font;

            li {

                a {
                    

                    &.active {
                        color: $color-1;
                    }
                }
                
                &:hover {
                    color: $color-1;
                }

            }
        }
    }

    ul {
        display: flex;
        gap: 60px;
        color: $grey-font;

        @media screen and (max-width: 800px) {
            display: none;
        }

        @media screen and (max-width: 900px) {
            gap: 30px;
        }

        li {
            cursor: pointer;
            font-weight: 500;
            transition: 0.3s;

            &:hover {
                color: $color-2;
            }
        }
    }

    h2 {
        font-weight: 600;
    }

    i {
        transition: 0.3s ease-in-out;

        &:hover {
            transform: rotate(180deg);
        }
    }

    .fa-solid {
        display: none;
    
        @media screen and (max-width: 800px) {
            display: block;
            font-size: 1.8rem;
            cursor: pointer;
            color: white;
        }
    }
    
}

.header-mobile {
    display: none;

    @media screen and (max-width: 800px) {
        position: fixed;
        height: 100vh;
        width: 100%;
        z-index: 100000;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        transition: 0.4s;
    }


    &.close {
        left: -100%;
    }

    &.open {
        left: 0;
    }

    i {
        position: absolute;
        top: 24px;
        right: 25px;
        font-size: 2.4rem;
        cursor: pointer;
        color: $black-title;
        transition: 0.3s;

        &:hover {
            transform: rotate(180deg);
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 40px;
        font-size: 1.8rem;
        font-weight: 600;
        color: $black-title;
        text-align: center;

        li {
            transition: 0.3s;

            &:hover {
                color: $color-1;
            }
        }
    }
}