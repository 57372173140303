#about {

    .about-container {
        // max-width: 1450px;
        margin: auto;
        padding: 150px 40px;
        display: flex;
        align-items: center;
        gap: 130px;

        @media screen and (max-width: 1200px) {
            gap: 60px;
        }

        @media screen and (max-width: 1000px) {
            flex-direction: column;
            text-align: center;
            padding: 80px 20px;
        }

        
        .left-about {
            position: relative;
            transform: translateX(-80px);
            opacity: 0;

            &.visible {
                transform: translateX(0);
                opacity: 1;
                transition: all 1s ease-in-out;
            }
            
            img {
                height: 380px;
                width: 450px;
                border-radius: 20px;

                @media screen and (max-width: 550px) {
                    height: 300px;
                    width: 360px;
                }

                @media screen and (max-width: 450px) {
                    height: auto;
                    width: 100%;
                }
            }
            
            .circle-dev {
                position: absolute;
                height: 195px;
                width: 195px;
                bottom: -40px;
                left: 300px;
                border-radius: 50%;
                background: white;

                @media screen and (max-width: 700px) {
                    width: 145px;
                    height: 145px;
                    left: 320px;
                    bottom: -30px;
                }

                @media screen and (max-width: 550px) {
                    display: none;
                }

                
                .circleword {
                    width: 175px;
                    height: 175px;
                    border-radius: 50%;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    animation: rotate 12s linear infinite;

                    @media screen and (max-width: 700px) {
                        width: 125px;
                        height: 125px;
                    }

                    @keyframes rotate {
                    100% {
                        transform: rotate(1turn);
                    }
                }
                }
                
                .emoji {
                    width: 65px;
                    height: 65px;
                    position: absolute;
                    top: 60px;
                    left: 65px;

                    @media screen and (max-width: 700px) {
                        width: 45px;
                        height: 45px;
                        top: 50px;
                        left: 50px;
                    }
    
                }
            }
        }

        .right-about {
            transform: translateX(-120px);
            opacity: 0;

            &.visible {
                transform: translateX(0);
                opacity: 1;
                transition: all 1.2s ease-in-out 0.5s;
            }

            h3 {
                text-transform: uppercase;
                color: $color-1;
            }

            h2 {
                padding: 10px 0 20px;
                color: $black-title;
            }

            p {
                color: $black-font;

                a {
                    color: rgb(120, 103, 175);
                }
            }
        }
    }
}