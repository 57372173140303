#projects {
    
    .projects-container {
        // max-width: 1450px;
        margin: auto;
        padding: 80px 40px;

        @media screen and (max-width: 1200px) {
            text-align: center;
            padding: 60px 40px;
        }

        @media screen and (max-width: 900px) {
            padding: 60px 20px;
        }

        h3 {
            text-transform: uppercase;
            color: $color-1;
        }

        h2 {
            padding: 10px 0;
            color: $black-title;
        }

        .project {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            margin: 100px 0;

            @media screen and (max-width: 1200px) {
                flex-direction: column;
                gap: 10px;
                margin: 60px 0;
            }
            

            img {
                width: 750px;
                border-radius: 20px;

                @media screen and (max-width: 1300px) {
                    width: 100%;
                    padding: 0 40px;
                }

                @media screen and (max-width: 900px) {
                    padding: 0 20px;
                }
            }

            .description-project {
                margin: 0 70px 0 30px;

                @media screen and (max-width: 1300px) {
                    margin: 0 40px 0 20px;
                }

                @media screen and (max-width: 1200px) {
                    margin: 0;
                }
                
                h3 {
                    color: $black-title;
                }

                .descr-project {
                    color: $black-font;
                    margin: 15px 0;
                }

                ul:nth-of-type(1) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin: 15px 0 20px;
                    font-weight: 600;

                    li {
                        padding: 0 15px;
                    }
                }

                ul:nth-of-type(2) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    gap: 80px;

                    a {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        font-weight: 500;

                        &:hover {

                            i {
                                color: $color-1;
                            }
                        }

                        i {
                            font-size: 1.5rem;
                            transition: 0.3s; 
                        }
                    }
                }
            }
        }

        .project:nth-of-type(2) {
            flex-direction: row-reverse;

            @media screen and (max-width: 1200px) {
                flex-direction: column;
               gap: 20px;
            }

            .description-project {
                margin: 0 30px 0 70px;

                @media screen and (max-width: 1300px) {
                    margin: 0;
                }
            }
        }

        .loading-project {
            display: flex;
            justify-content: center;
            margin-top: 50px;
            font-size: 5rem;
            color: $color-1;
        }
    }

    .btn-project {
        display: flex;
        justify-content: center;

        button {
            background: white;
            border: 1px solid;
            padding: 10px 20px;
            border-radius: 20px;
            font-size: 1rem;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                color: $color-1;
                transform: translateY(-5px);
            }
        }
    }

    .modal-main {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, 0.575);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 40px;

        @media screen and (max-width: 900px) {
            padding: 0 20px;
        }

        .modal-main-container {
            max-width: 840px;
            height: 85vh;
            background: white;
            border-radius: 5px;
            overflow-y: auto;
            position: relative;

            .header-modal {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: sticky;
                padding: 10px 15px;
                position: sticky;
                top: 0;
                background: white;

                i {
                    font-size: 1.6rem;
                    cursor: pointer;
                    transition: 0.3s;

                    &:hover {
                        transform: rotate(180deg);
                    }
                }
            }

            
            .projects-modal {
                padding: 15px 25px;

                .project-modal {
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;

                    @media screen and (max-width: 900px) {
                        flex-direction: column;
                    }

                    img {
                        width: 350px;
                        height: 200px;
                        border-radius: 15px;
                        display: block;
                        margin: 0 auto;

                        @media screen and (max-width: 900px) {
                            width: 80%;
                            height: auto;
                        }
                    }

                    .description-project-modal {
                        text-align: center;
                        margin: 0 30px;

                        @media screen and (max-width: 900px) {
                            margin: 20px 0;
                        }

                        h3 {
                            color: $black-title;
                        }
        
                        .descr-project-modal {
                            color: $black-font;
                            margin: 7px 0;
                            line-height: 1.3rem;
                            font-size: .95rem;
                        }
        
                        ul:nth-of-type(1) {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            margin: 7px 0;
                            font-weight: 600;

                            li {
                                padding: 0 5px;
                            }
                        }
        
                        ul:nth-of-type(2) {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            gap: 60px;
        
                            a {
                                display: flex;
                                align-items: center;
                                gap: 6px;
                                font-weight: 500;
        
                                &:hover {
        
                                    i {
                                        color: $color-1;
                                    }
                                }
        
                                i {
                                    font-size: 1.5rem;
                                    transition: 0.3s; 
                                }
                            }
        
                            
                        }
                    }
                }

                .project-modal:nth-of-type(2),
                .project-modal:nth-of-type(4) {
                    flex-direction: row-reverse;

                    @media screen and (max-width: 900px) {
                        flex-direction: column;
                    }
                }

            }
        }
    }
}