footer {
    background: $color-1;
    color: white;
    margin-top: 120px;

    @media screen and (max-width: 1200px) {
        margin-top: 80px;
    }

    .footer-container {
        max-width: 1450px;
        margin: auto;
        padding: 60px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 70px;

        @media screen and (max-width: 900px) {
            padding: 40px 20px;
        }
       
        @media screen and (max-width: 650px) {
            flex-direction: column;
            gap: 40px;
        }

        h3 {

            @media screen and (max-width: 900px) {
                width: 300px;
            }

            @media screen and (max-width: 650px) {
                width: 80%;
                text-align: center;
            }
        }

        i {
            font-size: 1.6rem;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                color: $color-2;
            }
        }

        .social-footer {
            display: flex;
            gap: 25px;  
        }

        .fa-circle-chevron-up {
            font-size: 2rem;

            &:hover {
                transform: translateY(-5px);
            }
        }

    }
}