@font-face {
    font-family: "Poppins";
    src: url(../../public/assets/fonts/Poppins-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(../../public/assets/fonts/Poppins-Light.ttf);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(../../public/assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(../../public/assets/fonts/Poppins-Bold.ttf);
    font-weight: 700; 
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url(../../public/assets/fonts/Poppins-SemiBold.ttf);
    font-weight: 600; 
    font-style: normal;
}


// ajoute couleur par defaut, ex : $color-1: black
$black-title: #2d2e32;
$black-font: #707070;
$grey-font: rgb(228, 228, 228);
// $color-1: rgb(12, 142, 146);
$color-1: rgb(12, 132, 135);
$color-2: rgb(235, 205, 151);
// rgb(42, 131, 131)




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: grey white;
}

body {
    font-family: "Poppins", "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color: $black-title;
    overflow-x: hidden;

    .about-container, .competences-container, .hooks-container, .contact-container, .footer-container, .presentation-main, .projects-container { 
        max-width: 1450px;
    }

    ::selection {
        background-color: $color-2;
        color: #FFFFFF;
    } 

    li {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    // règle pour les ancres
    :target::before {
        content: "";
        display: block;
        height: 10vh; /* Hauteur du décalage */
        margin: -10vh 0 0; /* Décalage négatif pour compenser la hauteur */
        visibility: hidden; /* Masquer l'espace ajouté */
    }
}