#presentation {
  height: 90vh;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  
  @media screen and (max-width: 900px) {
    position: relative;
    height: auto;
  }

  .presentation-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // max-width: 1450px;
    padding: 0 40px;
    width: 100%;
    color: white;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 50px;
      margin: 30px 0;
      padding: 0 20px;
    }

    .presentation-left {
      margin-top: -150px;

      @media screen and (max-width: 900px) {
        margin-top: 0;
      }

      h1 {
        font-size: 3.5rem;
        line-height: 4.2rem;

        @media screen and (max-width: 900px) {
          font-size: 2.8rem;
          line-height: 3.4rem;
        }
      }

      p {
        margin: 25px 0 50px;
        font-size: 1.1rem;
        line-height: 1.70rem;
        color: $grey-font;
        font-weight: 400;
        max-width: 490px;
      }
      
      .social {
        display: flex;
        gap: 25px;
        
        svg {
          width: 25px;
          // fill: $black-title; /* couleur des icons */
          fill: white;
          transition: 0.3s;

          &:hover {
            fill: $color-2;
          }
        }
      }
    }
    
    .presentation-right {
      // position: relative;
      // z-index: -10;
      
      .circle-main {
        height: 2300px;
        width: 2050px;
        border-radius: 50%;
        background: $color-1;
        position: absolute;
        z-index: -1;
        top: -1460px;
        right: 440px;


        @media screen and (max-width: 1400px) {
            right: 0;
        }

        @media screen and (max-width: 900px) {
            right: -400px;
            top: -1560px;
        }
      }

      img {

        @media screen and (max-width: 1400px) {
          width: 550px;
        }

        @media screen and (max-width: 1050px) {
          width: 430px;
        }

        @media screen and (max-width: 900px) {
          width: 500px;
        }

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
}


// .circle-main {
//   height: 1100px;
//   width: 1100px;
//   border-radius: 50%;
//   background: $color-1;
//   position: absolute;
//   z-index: -1;
//   top: -100px;
//   right: -450px;
// }

// .circle-main {
//   height: 2500px;
//   width: 2000px;
//   border-radius: 50%;
//   background: $color-1;
//   position: absolute;
//   z-index: -1;
//   top: -1680px;
//   right: 450px;
// }

// .head-port {
//   position: relative;
//   z-index: -10;
//   overflow: hidden;
// }